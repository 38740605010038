<template>
  <div class="container">
    <v-select v-model="selectedTable" :items="auditTables"/>

    <v-btn v-if="selectedTable" @click="inspectTable">Inspect Table</v-btn>

<pre style="white-space: pre-wrap;" id="console">{{tableStatus || "No Status"}}</pre>

  </div>
</template>

<script>
export default {

data : () => ({
    auditTables: [],
    selectedTable: null,
    tableStatus: null
}),
mounted() {
    this.fetchAuditTables();
},
methods: {
    fetchAuditTables(){
        this.$axios.get('/db-audit/tables')
            .then( (response) => {
                this.auditTables = response.data;
            })
    },
    inspectTable(){
        this.$axios.get('/db-audit/table/status', {params: {className: this.selectedTable}})
            .then( (response) => {
                this.tableStatus = response.data;
            })
    }
}
}
</script>

<style>

</style>